import { default as _91_46_46_46slug_93Qv3uKeTLMXMeta } from "/vercel/path0/pages/[...slug].vue?macro=true";
import { default as CurrentProjectsPFg29Ym5E8Meta } from "/vercel/path0/pages/CurrentProjects.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93Qv3uKeTLMXMeta?.name ?? "slug",
    path: _91_46_46_46slug_93Qv3uKeTLMXMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93Qv3uKeTLMXMeta || {},
    alias: _91_46_46_46slug_93Qv3uKeTLMXMeta?.alias || [],
    redirect: _91_46_46_46slug_93Qv3uKeTLMXMeta?.redirect,
    component: () => import("/vercel/path0/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: CurrentProjectsPFg29Ym5E8Meta?.name ?? "CurrentProjects",
    path: CurrentProjectsPFg29Ym5E8Meta?.path ?? "/CurrentProjects",
    meta: CurrentProjectsPFg29Ym5E8Meta || {},
    alias: CurrentProjectsPFg29Ym5E8Meta?.alias || [],
    redirect: CurrentProjectsPFg29Ym5E8Meta?.redirect,
    component: () => import("/vercel/path0/pages/CurrentProjects.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  }
]
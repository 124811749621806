
export const enabled = true
export const isDev = false
export const i18n = false
export const adapter = 'vuetify'
export function dateConfiguration() {
  const options = JSON.parse('{}')
  
  return options
}

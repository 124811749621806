import {ClickOutside} from 'vuetify/directives/click-outside'
import {Resize} from 'vuetify/directives/resize'
import {Ripple} from 'vuetify/directives/ripple'
import {VDialog} from 'vuetify/components/VDialog'
import {VExpansionPanel,VExpansionPanelText,VExpansionPanelTitle} from 'vuetify/components/VExpansionPanel'
import {VBadge} from 'vuetify/components/VBadge'
import {VDatePicker} from 'vuetify/components/VDatePicker'

export const isDev = false
export function vuetifyConfiguration() {
  const options = {"defaults":{"VBtn":{"color":"blue"}},"blueprint":{"defaults":{"VAppBar":{"flat":true},"VAutocomplete":{"variant":"filled"},"VBanner":{"color":"primary"},"VBottomSheet":{"contentClass":"rounded-t-xl overflow-hidden"},"VBtn":{"color":"primary","rounded":"xl"},"VBtnGroup":{"rounded":"xl","VBtn":{"rounded":null}},"VCard":{"rounded":"lg"},"VCheckbox":{"color":"secondary","inset":true},"VChip":{"rounded":"sm"},"VCombobox":{"variant":"filled"},"VNavigationDrawer":{},"VSelect":{"variant":"filled"},"VSlider":{"color":"primary"},"VTabs":{"color":"primary"},"VTextarea":{"variant":"filled"},"VTextField":{"variant":"filled"},"VToolbar":{"VBtn":{"color":null}}},"theme":{"themes":{"light":{"colors":{"primary":"#6750a4","secondary":"#b4b0bb","tertiary":"#7d5260","error":"#b3261e","surface":"#fffbfe"}}}}},"theme":{"defaultTheme":"dark","themes":{"light":{"dark":false},"dark":{"dark":true}}},"ssr":{"clientWidth":100}}
  options.directives = {ClickOutside,Resize,Ripple}
  options.aliases = {'MyBadge': VBadge}
  options.components = {VDialog,VExpansionPanel,VExpansionPanelText,VExpansionPanelTitle,VDatePicker}
  
  return options
}

